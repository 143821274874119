/* Reset some default styling */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
  font-size: 12px;
}

body {
  background-color: #0D0215; /* #24022b */
  color: white; /* White text color */
}





